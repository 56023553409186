import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "JSConf JP 2024に プレミアムスポンサーとして協賛しました！",
  "date": "2024-12-06T09:31:34.000Z",
  "slug": "entry/2024/12/06/183134",
  "tags": [],
  "hero": "./thumbnail.png",
  "heroAlt": "JSConf JP 2024に プレミアムスポンサーとして協賛しました！"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは！人材プラットフォーム本部で技術広報兼エンジニア採用をしている重田(`}<a parentName="p" {...{
        "href": "https://x.com/Shige0096"
      }}>{`@Shige0096`}</a>{`)です。2024 年 11 月にメドレーにジョインし、初の社外イベントに参加してきました。`}</p>
    <p>{`今回、メドレーは 2024/11/23 に九段坂上 KS ビルにて開催された「`}<a parentName="p" {...{
        "href": "https://jsconf.jp/2024/"
      }}>{`JSConf JP 2024`}</a>{`」にプレミアムスポンサーとして協賛させていただきました。`}</p>
    <p>{`JSConf JP は一般社団法人 Japan Node.js Association によって企画・運営されている JavaScript に関する“⁠ お祭り ⁠”です。日本と海外の Web 開発者を繋げる目的で企画されています。2020 年から開催され、今年で 5 回目。今年もフロントエンドエンジニア・採用人事・技術広報担当を中心に多くの参加者が訪れました。`}</p>
    <h1>{`会場の様子`}</h1>
    <p>{`会場は九段坂上 KS ビル。今年はオンライン・オフラインのハイブリッド開催でした。現地チケットは売り切れてしまったので、オンライン配信があるのは嬉しいですね。`}</p>
    <p>{`当日は 4 つのトラックに分かれ、時間帯によっては 4 セッションが同時並行していました。
セッション数が多くとても充実したイベントになりました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "718px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/30f55c3e380fc59c71e5a97523eed3b3/57dc1/image8.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEHElEQVQ4yx3De1DTBQDA8d8/9Uddd/VHnndqVqdd2stMpUsrUxNF0QTROxEUN0Ad4sYGDLbB3vvt8dt7jG1u8hBFBoIZpoiETEVExEi9zDrzH+88X/VH1+uub3d87j5C2KOnUa/CZqzCYdFgqC1Dq9yFTiMj+/15FL8+A/n82VQtX4Jl3Wq6i7ZwpbKQsZoSOpfOoW7Gc5S+KKB55XkMM19AcFuraNRXYWpQEwm5sJvUuE2ViMYD7NnwKQUzX6b4zVnUr19Lv7ae+04jTw27eaZYw4O8BXy7dCbuuS/R9PYskoveQNhXXsje8mKKi7YiuV0EJAc6TSlm/X6qizdRNPdV9n+4kG6ngweXRvlteIC/I0r+q1gG+5bwJG8BU7nLGNu1Ge+aJQhy+U5qajVUKkqoq1ZiqNeirJBRUyWnJO9LNs+bzbaFb+GtOMA3TVGGjFoyZes5n/8eZ7csZCD3HY7mZKFYtYyPF8xBMJl19PR003+yC5tRTZlsB5LTyKmuGMrSPD6Z/xprP1qE/KvNKPLyqdy4EVX2OhQrv6A0O5vS/I2szvqA7Rs+J3flYoR63UEqVBq8gQihkIRPshGPh2lvSyJadVgMNUTcIn0nehgePMfE+FVufj81fXLiGkPDI7htRtKHJGr370SQHBrUqjKsdiO9Jzrp6uzgcCpBW0uStpCfFslDX3sH184Mcn9ikqdPHvPXv//w57Pf+ePxI27de0QsluBYwk2DSoYwdDpFwKmmRFZIIh4mFPRPDwc8NPtdRE1GWkIhhjqOMz46yu1bt/j1l3s8nLzNwzt3uTT1M5LkIyHpqC7fhtDbGcCqL2dD7jo8HpFQyE8gIOH1iISdFiJWIwmXSG88xcjQd0wNZ/gpc4V74ze4e+dHLoz/gM/nx2eqRFG4FuFwkw5zfRmyPYUEAy4iYS8+r5OA30XQ3EiwQUezW6Q1EqG/q5vM4HmujI5yY3SMifHrXL46STIRpVEtR7Z1JULUX0dbwk4q5iIYEPH5RPw+kUjITUQSCYoWAlYjUZ+Htnicvp4eBgYGGL4wzEjmIn1f9+Oym1DKt1G0ZRXCzat9XL/YTWerH8ljme4UjTgdekSHAdGmw2U14BFN+DwisWiE9iPtpNNp+vp6SaYO0aDTUFKQzfac5QgRSYtkV+G2aXDadYjWemxmLQ6rFqetjqBkor5GiblBS2uzl+awh2hYor0lSmuyiZZkBLNexY5Nn5GfnYWgU+/Gqt+Ly6wkHrYTDzumRwNWUk0OTnfHOHbYR386wfi5o2TOtNHTESQWMBDzajFodnOgtIDtuSvYtGoxwsljPs6eaKK7XeJI0o3FoMJpqUU0aYiHrQyeSjF2voPJTJprI8e5fK6V/nQIyXYQY/VOFCU5yApzyFufRfaKd/kfhs4FcpkowEkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "会場入り口",
            "title": "会場入り口",
            "src": "/static/30f55c3e380fc59c71e5a97523eed3b3/57dc1/image8.png",
            "srcSet": ["/static/30f55c3e380fc59c71e5a97523eed3b3/5a46d/image8.png 300w", "/static/30f55c3e380fc59c71e5a97523eed3b3/0a47e/image8.png 600w", "/static/30f55c3e380fc59c71e5a97523eed3b3/57dc1/image8.png 718w"],
            "sizes": "(max-width: 718px) 100vw, 718px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`会場入り口`}</em></p>
    <h1>{`メドレーブースの様子`}</h1>
    <p>{`メドレーブースでは「医療体験あるある」をパネルを用いてアンケート調査しました。
エンジニアをはじめ多くの方にお越しいただきました！お越しいただいた皆様ありがとうございました ✨`}</p>
    <p>{`特に多かった「医療体験あるある」は以下 2 つ。`}</p>
    <p>{`① キャッシュレス決済がしたい`}<br />{`
⇨ キャッシュレス対応が進んできたがまだまだ現金対応の病院が多い`}<br />{`
⇨ 現金を引き落としに一度コンビニに行くことがあった`}</p>
    <p>{`② 待ち時間が長い（ほぼ同率 １ 位）`}<br />{`
⇨ 予約したはずなのに待ち時間が発生している`}<br />{`
⇨ いつも混んでいるので病院に行くのが疲れる`}</p>
    <p>{`実際に `}<a parentName="p" {...{
        "href": "https://clinics-app.com/"
      }}>{`CLINICS アプリ`}</a>{`の画面をご覧いただきながら以下のようなお話をしました。オンライン診療を受けたのち、ご要望に合わせて薬を Uber で配達することも可能であることや、何よりキャッシュレス決済もスムーズにできてとても良い！などのお声をいただきました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/866b0908ab545c00e3822eb7a0a6a8ed/c58a3/image4.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "133.33333333333331%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAQBAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAbWImYKukE+Nd3nJf//EABsQAAICAwEAAAAAAAAAAAAAAAECAAMREiEj/9oACAEBAAEFAlVShUGPWNqz4ZObQ21fKpc2HfgLtDP/xAAWEQEBAQAAAAAAAAAAAAAAAAAAERD/2gAIAQMBAT8ByI//xAAYEQACAwAAAAAAAAAAAAAAAAAAAQIQEf/aAAgBAgEBPwGlI0//xAAbEAADAAIDAAAAAAAAAAAAAAAAASEQMQIRgf/aAAgBAQAGPwJPo0SCheJEJY8Ibx//xAAeEAEAAgICAwEAAAAAAAAAAAABABEhMUFRYXGRof/aAAgBAQABPyFiXvxfM3X5hNAqV0pc1Ot+mMLgrucw3xFc0vyVhDQcGLwuNXLc/9oADAMBAAIAAwAAABBoDQL/xAAXEQADAQAAAAAAAAAAAAAAAAAAAREQ/9oACAEDAQE/EGQcvH//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEx/9oACAECAQE/EDlsKTP/xAAcEAEBAQEAAwEBAAAAAAAAAAABESEAMUGBUcH/2gAIAQEAAT8QSgg2SdfOHlyABufzpzKYvCiCwYLXOvoCzlcRHPvilZFYvvrIGviuZhfB3PbyFIJo9smCsvV7L9Xv/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "医療UXの「ここなんとかならない？」",
            "title": "医療UXの「ここなんとかならない？」",
            "src": "/static/866b0908ab545c00e3822eb7a0a6a8ed/e5166/image4.jpg",
            "srcSet": ["/static/866b0908ab545c00e3822eb7a0a6a8ed/f93b5/image4.jpg 300w", "/static/866b0908ab545c00e3822eb7a0a6a8ed/b4294/image4.jpg 600w", "/static/866b0908ab545c00e3822eb7a0a6a8ed/e5166/image4.jpg 1200w", "/static/866b0908ab545c00e3822eb7a0a6a8ed/c58a3/image4.jpg 1500w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "713px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a769ad98f86063d5511791770546b581/01267/image5.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEG0lEQVQ4ywXB6U+TBwDA4Xe4LdlhFrMry/bBmLksS6ZxHgV0BlCL3MghhUIFWmxLC5RyiEDLWaDcthwtrS0tyAoFC+OUHtqizCuMXR9M/OJf8tvzCC1aGerSHEyGVlwOL0uLazgdMxjvNtDeXEdzgx69rp5JywQP5hdY9gdYWVmnQd9EbmYGORmpqJVVJCacJfGiDOG2ooJySSa9bXpM7a30GzpYfOAn+iTGQL+ZCeskHcZO6ut0FOTlIZWUcFtRxaUEEakpl9EqK5HLShCdP8Op02oEXXUF+loF98YGcTunsFuHadJVM9TfiW3Sgtk8QFlZGVlpYkolhWjUKlruNCMvl1GUn0tBXjbtba0UFRWSdaMHoUEjJT35PE1aKWqFlNycbFJ+FXEt6QI6bQUTY91Yx/roN3XTaWjFMjqE1+3C7bAzPjLAkLkXu22amlotY7ZthErZTYx31Ax06sm+msDVS2fJFieikOXQ2FSFw9rH/UkT1sFGvM4RAkseZu3juF1Otre3CYZD9PX1UlFRRs9gD8K01cz4SCdzXhclknyuJIkoLclg0dPPrEGFz9NFKDRDJDjN6pobY2cL/pUHRJ/FCEdDhJ+E6egwkJslprgwA2FkqIsBUxsjQ71MW6bZ2Qixt/eC9ZUAE3cMuO02Qo+neBpzoLwt5ciHH2MeHyIS3GJnM0AovEtrSyOpKYkoSm8gpImTMRn1NGjlzLvmefPfO/45fMPqYgDbpJ1Zu4vdoJO5mUGUsnLkSjl7B69YXQuwubVBMBKiuaEGcbIIjaII4VZhKurceKQXj7PsmWU/+pJYKIrL5qDP2IVz2k7w1TYLTgvK4hJ0tWqex2IEQxHCe1Ei0Sj6GiXXky+gKs9DeOhoZ1ifiy7nNDvLfg6eH7KzE2HN95DAvA9vv5l7TVqetnfzuqaNw/sL/Lu4QXjex8Z2kCf7z6lVVSC+/AtK2Q2EDbsKj6mQfk0ywy23GDVqMRu0TA104/dO4cjPpkcQsH72JUs/n2MnX8pBXjFOcSZup4fdYARNZQlXLp6ioigdoUeTxHD9VRzGTOxtaYzoUhjXX8PWnMZsXz5z6Qksff497u+OM/3pMazCEayCQPVHR5kaHeLR5jq3CtJIFv2ELO8awoK5iOCMnIPFOt5utfJu18Db9bu8cFXjHyxi5soZvMJRlo9+y9Y3J9k88SMPPzmGKu49WorjWbXUUVsQj1j0A6VZSQhRj5rXvjr+8uv521/PwUItL2Y17DuUPLNX8qgrG3fWKSZPfIE17gMcQhy/CXHI34/DXHWBzd7reBsv03jzHIXXExBe+up45dPxcqGWP+Y07LlVPLZVErJICY1K2LcW86ejhEOHhFhfOsvl57Cc/IrG01/zuymD2ISE1d4M7PVJyDLj+R/Lsd/L2f1f6AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ブース1",
            "title": "ブース1",
            "src": "/static/a769ad98f86063d5511791770546b581/01267/image5.png",
            "srcSet": ["/static/a769ad98f86063d5511791770546b581/5a46d/image5.png 300w", "/static/a769ad98f86063d5511791770546b581/0a47e/image5.png 600w", "/static/a769ad98f86063d5511791770546b581/01267/image5.png 713w"],
            "sizes": "(max-width: 713px) 100vw, 713px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "722px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c0592f82e2697c9f9fe40793febc7646/d44c9/image1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEFUlEQVQ4yy2Pe0wUBBzHf9BlKi9BFwKpZZA6dYrTzImozUJX0uxlW0NIhWWgPGRDUkPuQCHwgEteindyII+Ou+MecHfcgTwCVEBBRCwNV81Kt9jaamvV9mmSf3z2/e37x2ffn/QP6hmbtGAx6ylUqajUlFKl0VB05gx2kwlbdw82jw2dvopGpwub04Hd0Y7VbvmfNitmqwVbextGsxEZ+c7D1PQQA0MuipRKKtRnadJpSU5KoqKklBpNHkWnM3F0u/Bc68fh6cDubJ8RtdosMzJjqwmnu4OCAhVisDZz7/EQd34coV57ngua0hmhQV9Lk05H5pGDvBsbTc7xQyhPJuPo6sLd24er0zOztMVswmRpxdZmJ/FgHFJ1XkvRuXwcPS006CtR5+VSVpA/g0GnJefYUTKS4ynOz6S4MJfRaze4PTzK2NVhbnwzQK+nE7PVilan48PdO5Cmulo2b3oNESHjswR05WVcLFNjvKTl68pKet12bn4/yq0HE4zdGeXbwREG2jrobLFwu6cPp8WGsdWG8tRJotaEI4OeDkoLVMzyFnZGLeNS6Wl058q5XFFBTWkZvSVfMZ6YythHB7ials1t5xX6mo10NTZz09jK5eoadHUNJOyNJSLUH+lrb6fbZqYoYxd5cStw11XhqrmEtbScK9mnGNr6DvdlIQ9kMVML1jIVtYcftr/H/eXRjB/OYvhqP6rjWWxe/RKhQbMRW0M9g64OmtTpGDL2cvf6AGNtbYw53FwP38Sj7XH8viOBibWx9OjsOOs99G+L59eQDYxH7eaniWFOZ6WwKPBZlgT7Ia7mRrrtNhz5SgZb6uh0tjAyeZ2ulMOMr47hn8F7/KG1MbHubSYfPuLuX/8y1WBlsN7Arex87ml1TD68Q2z0y4QFzUI8ZgNurRbz0jUYtkXTvHUL5u1b6X1+KY927edv9WX+jMvmYXg0j28OMX1/kml3B78cOsZvGh3T2/bw8+FkeiNXErPKD2nQX6R83z4KRSgSQS2CRoRKmUW1wo8L3r7ovP3QKgLQBIdyLuQFqkMW0eIdgMlrHq3ij1lm0yw+nHgjBClM3YUyaQdHFgeTLMKhZ7xIFOGTp8SLsO8p+0U48DSf9AlPbi/hYxGS1y8gLy4CKUjayNH3V5DzQSRKn9nkiVASEU7J8mWcCQzkyyWLyQ0LJTdkIYWLwsgJ8CcnNJicBUF84e/LMRFyXpzHxdR1aBJXIWc/fZXCA+spS9tC2VsrKX7y8vz5VEa8gnquD8V+/qgCAlAFBVEcGES+ry+qwHmofOaQq1CQJUJ1/CoaszdyPjkS0X/+JrXZMTQc34k263VKIkPJFCF9rheZvgrSfbxJe05IUQjpc4TUOUKaQmZEJ0RICVNQkrgc04kt1GZs4D8+b9d0tnDWcQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ブース2",
            "title": "ブース2",
            "src": "/static/c0592f82e2697c9f9fe40793febc7646/d44c9/image1.png",
            "srcSet": ["/static/c0592f82e2697c9f9fe40793febc7646/5a46d/image1.png 300w", "/static/c0592f82e2697c9f9fe40793febc7646/0a47e/image1.png 600w", "/static/c0592f82e2697c9f9fe40793febc7646/d44c9/image1.png 722w"],
            "sizes": "(max-width: 722px) 100vw, 722px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "713px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2a2ad313c601e194094966d00c935262/01267/image13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAENElEQVQ4yx3Mi0/UBQDA8V8K6nysXFbTqTSFgE5RSJuFIKkrTkYgM70jBE70UAJBlJfKMe7kEI7neafHDY4DFA+9IQScx/ESEERBecgZmk/Mls22lpttZfu28Qd8PoJZc5TitD0UZUSRnRLNuXIVTQ0GkhNTUOcq0RZrKFCpyFcqMer11NdUY6k1crXJQpv1Ig0GPeYzZ7BZbfR13kKoKD+N6ngSOelxKE8mYdCfosvRiMPeTW/XAPbWDsyVJvQlp2m+bOZinYmGuirOlRVQflpB6oHvyU1PwXxWS372cQSzLp8qbS4aVTI6XS6d/fXcGrvCw0c93L/fzehYO+MTdnq7L9BpM9F4qRJ7q4VrDisPxweoNhm42mqhvlpHdmosQlXZKbTqdPJOxKMtz2FgrJknv1/n7X/P+Oftc17+Mcir1w7uOhtxjjZxp7+FsYkuhkc7GL/dzcC1Fm4P2ihSpXEyRYpQp8unPC8D5dFYjqcfRG+t5te/fqJn9BrdN9qZfjLM9POb3BzooKxIhU2t5orJiN5k4LLFzLN7Q0zc7CA7JY7sxEgEc6kSTXYKJxKj2LUzmMJSDY9ePqX0Qi3KQjUj7TYeXB/C2XuDHUFBZCYloko8RICvD4EbPiNPkYWpNI9k2W5UyTEIDXo1BnUaGdE7SfBbh7U0H+fTZ4zeGWaox8GDwT5GGmqRhoWz2s0Nk95IVYkR30/ciQ7Zgp+XJyGbNvKDJBxDgRJhslGDRXOE7Wu8yRAW0RIhxdl/FYdZh6OyFKf9MpnyGOa6uiAIAj5rfTkYvQ+x/+fIo3YhCw1lvUhEZnAIvwwNIDgU4eRIt7DQ1ZU9wiyawnYwYtXyY1kOrWfyuNdagzxSgsus2TOhl4cH67xFWIpOEbEtgDUiEWI3L3qOZPByYgihLUvMxbRQVi55Fw9hFqoVSykN38ywSc2Lnks8bDNRdFTGHBfXmXBveChf+IiI2rCWL0WevL9gAdrlbrTvj2Sq9TxCfdJXjJTHoovfitjrI3wEgZSQAKYaK/h7so9H9irsJZF8uurDmTAwIIBN6z3J3x/E3NkCIX4rqT+0iReGcCb1mQhTxhjunt3D/XMRFMf4McdFoEC+nbL0ONL2SVDIgpmojsPXc9lMmHUslfxkKRJ/N9yXLqYudSvSzauZOp/Fb31NCK+scn42RfKgUoJSuh73JfPoLAhDLvbGRRCQi0X0lnw3k328ZB6KwzKe9FajiA4keIM7ukPbqDi4mebcXZwvykWYviBjskLC+Nnd1BwOwN9tIfasrymM82fVB4s48I0314sjSBB7YkwKpEkRQk/xXuhKxWmMxKEK4XGNjCPfipjv8g7C49pYJgwSBkt20q8JQyvdSIs8CH1CEBs9lqFN2DoDp+ui+dO6n9fN8by+coA3LfH82ybnje0YarmYFe/NZ/ni+fwPkzXmqkhQBrwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ブース3",
            "title": "ブース3",
            "src": "/static/2a2ad313c601e194094966d00c935262/01267/image13.png",
            "srcSet": ["/static/2a2ad313c601e194094966d00c935262/5a46d/image13.png 300w", "/static/2a2ad313c601e194094966d00c935262/0a47e/image13.png 600w", "/static/2a2ad313c601e194094966d00c935262/01267/image13.png 713w"],
            "sizes": "(max-width: 713px) 100vw, 713px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`多くの方にお越しいただきました 🙌`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8174d54a4dfb3e51bdc0fcde8980de74/37523/image2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEMElEQVQ4yx3Oa1ATBADA8X1DXgMZb4xEDAzx8kF1xmVk2vEwUySzQrPjIgw5kPFQYkNkkw1wPGSC4mRTBwP25v2myUMEGXpSil1aVtrLruzquuv6d/Lh9/0naK4uwKorp99wCoO6kC7NUa5ZlXScl1JflceZ+hJU1RJOyPKRKaR0mC8zMT6Aw9HH5MQgU5PDTE0MMthvpq+rBcH5ilw0VWI0qnwMGhl1FXnozhRj0RVh1x5FV5tHrTwLhSSdytJMlJJ0amWHUSsKaKop5eJpCdbzElrr8mk9nYfgbHkW6rJM1CcP02mQo20sQt9cjF6bTVubmIaag+jUHzM8dJzenjKGR1R0W08hLSogJ1dM9pFsrnyu5tGjdn542PZ0mM05RRZNlTn0W8ox6KSY20tYuF3LVw/U9HUV0G0V8+U9NdNONbPzGsoqingtKZn4lHd5fddeFKpj3L1Rz41pFYImZRaaymwu1uVhuiRFe7YQo/4zeoxHGLAdQVeXSnP1+/RacrG2ihmyS8jN/4hXk3aQuHcPW3cnc7Q4g9szVdy4qkTQpMiksTyT/fsSiE+IIyFpG+r6HB78qOXnvw04HMdxOEp5/G8Hi48M3HrYQXe/isRdO4nfl8z2PclcMsp48k8bv/yhR6BRZnFCnErEmnCej15DzIsbKJNm8N1jPY//MzM+KWN8Us7vdHLnJxOz91pZ/Ladwvx0tuzcwbaU3Rg7K3nyVxu//alH0CjPQFl0kNiNERQlb6YhPYmBGjG35tpYcBqZGtIwNXSBhZtWnE4bCwuDtJkbSEx5m7jEJOKSEkh4ujSU8839DgRVxQdRyzPIeG87Ze+8hCb9DSyqQ1wfaWeqXce0Rc+0zcB0r5lrwz3kZOeyYXMckes38vKWLUtit24ldtublJZIEJws/AB5YSrVRQeo3h+L6tN4TCYF0/0tzA2YmBuyMDdsZd7RS8ahTFZHryc8KornoqOXRL6wjrWb1rM2ZgMbY19BIMlKQXHsQ672t/LFqJGprgt02xsYt2mZHzQzO2Di65kRtGdqCAxdRVhEJM+sCmdFWBihq8NZGbGasDURhEdFErEuCkFu2lu0qEtYmOjmjvMKv36/yHWHnTGThvkhC84RG/dnR0k7kIqXbyAhz64kICQEv6AA/IL88Q0MwC84gKDQEELDwxCI03ZibDyOXVtFx9ly7jodzF7pYsx8gdm+dm6O2rg51k3Mphi8fHzxFonw8lmOcLk3Qm8h3iIhfgEiRP4iAkOCEbSqPsGukdHXokajLGTMpmNm1LY0nOm8zO0xO12XzxEUvAJ3TyEursvw8PTExc0Vd093lvsIcfNwxdvHC5G/LwJ9RRqmxlLGzBqaq4qxNFczN2JlzKhhwn6JxfEeTsuluLsL8fDwWOLq6orLMheEQje8vNzw9nIjKNgH/wAR/wNYeegeceCZQAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ブース4",
            "title": "ブース4",
            "src": "/static/8174d54a4dfb3e51bdc0fcde8980de74/37523/image2.png",
            "srcSet": ["/static/8174d54a4dfb3e51bdc0fcde8980de74/5a46d/image2.png 300w", "/static/8174d54a4dfb3e51bdc0fcde8980de74/0a47e/image2.png 600w", "/static/8174d54a4dfb3e51bdc0fcde8980de74/37523/image2.png 720w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`自身が開発しているプロダクトに込める思いは人一倍 👀✨`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "689px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/43f572106705b545bd1f5468b8b0aad2/0f79a/image11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAENElEQVQ4yyWOW1STBQCA/+fSEhXOxoBQDh4QUC6igQShgO3IXTsakoKiyCUEPCoQQirjouKCKTBHg0HCNnHAcCgTGOrGYGLcElLsFN0Ue+rB176Op4fve/zOJ4wbm+hWnOVyWSa1ZZkoqop4oGvgmUXDpKkZs66e/o5ahrRyLAYF46YWpkfaePawkx8t7fxs17PgGGTCcocJkxJh2vwd5u9raKrKR155guba09iMSl7PGll+cpv5sQ4eDVznnlHB2L12hnsaeWRs4aWjh9+fGHkzZ2Jluo83U13MDd9EWBzrwNpTj/pKEU2XcmmSFWLtb2JlfoBXc/0sWbUMGy/SOtrI9aZO0hOjyU2V0mkoZfDFLTpts/TYhql78BMjo93/B2099WgVJbRdKaRFdooR3TWWHVpezfXyi13L6F0ZNkclF4oz2fvJdlqzU6kpT0T/WzW3Zn6lUTdIzsVW8gpOIcwOqZgyfovm2hkKjuyj9MRB7qqrWBxr468f9CzbdYybL6OdzeNMaRaF8ZF05SUhL8mn92EfbSoF2YezKElPJj1RimC9I2fapOBkWgISkSv6iuNMddXw9L4SpayQosxDVF/IZnBGxaBGQUtGFPrzudzotRCbd5XobUGkJe4lLSmew/F7ECzdl5kyNpAQF0ns1k0s3TjFQrcMXVM5/t5e+Hh7ExEaikHdzN8LNkzNl3h0u5kxq5mE/HP4enkTFhJEiL8PufulCL3KcsyaCwT7+RCz2ZM7RcmM3DhLTekxtm70JPHjQCL9NnGztoKVpacsWE1MDN5ifrgL5dUKfL024u+7iegQf2QFBxE6rhZiaC5Fr77O2dwsijM/57byG9prcjgvDUJxIJKGnENYezUszz7muX0Iu6GdPvU1zuVnERoUTGBAAMeTdpOUHoOgkuVg61fx7z9/8nZliT8W7NzVyKksTEV2NAJZRjKauq+519nI84khXkyamTFrqSzOwdd3C9uCQgjw8yfYL4DQlAiEKyVHmehTMz82wJJjBN6+RlVfTbI0lmPZqchLjqKrymfyvp45Sz/PHpuoKytmT9ROPNwkbPBwx13kgjRqJ6VfZSFk7t/DuKEV+0AXjw1tPOzvJn1fIlkHUsg7nkH56SxUdeUs2oZ4OWnm0rlCvNw98Pb0wF3kjHjdGrw9xIQFbiY1LhKh7OQBFoZ1WA3tmNRyCjLS2LFlM4dTpHyRvJcvUxJpqK5kcrCbEZ2KXRFhSMQiPN3ESFzW4ubshJvLOz7EZ4MrQmzYFqJCA9HUVzDQoWR7UDAxO7aSlhBDWko88XG76L5Zz+tFB47RAUKDAxG5OOP27m79GkROqxGvXY1k3Sokzh8guL+TIJCZ9CnH9ifgKpYQGx5I0u5wco8cJDx8B5/FRbM0Y2PW0s9Hbu5IxGJcXZwRrXdCtGYVog/fx9XpPSTrV/MfK/zg+95NU4QAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ブース5",
            "title": "ブース5",
            "src": "/static/43f572106705b545bd1f5468b8b0aad2/0f79a/image11.png",
            "srcSet": ["/static/43f572106705b545bd1f5468b8b0aad2/5a46d/image11.png 300w", "/static/43f572106705b545bd1f5468b8b0aad2/0a47e/image11.png 600w", "/static/43f572106705b545bd1f5468b8b0aad2/0f79a/image11.png 689w"],
            "sizes": "(max-width: 689px) 100vw, 689px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h1>{`メドレー登壇`}</h1>
    <p>{`当社の髙橋(医療プラットフォーム本部)と德永(人材プラットフォーム)が登壇しました。`}</p>
    <h2>{`高橋のセッションの様子`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "694px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/eda5ea72d967252fd62391e448803d0f/31198/image12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD9UlEQVQ4yx3P2W8UBQCA8X3WhESOSsCQeICWaCIKGhNCFUtttaUU7LXbUnpRu+3es+fs7OzszuzMzsye3W67XVpKKwTEICKhDzypMTEx+kD8GzQI0RcDxgc/A//Al9/n0E2bhKwiKgalSo2QIFCw89z55jphwUc84sPWZTQlRjYVIi160bNh0gkv467T+D1TJKJeygWVL66s4TBtk/rKKka5gV5c4sLFdSrVEpoqY5oqpaLGxWaFZr1Ao5Ynp4RRkn7UVJBaIcXna0VuXmuwdWuTWzcu4SiVLDY2N9i4fJVwVCKekCmWq+h6jlLRRs3ImLkMVi5DISeTjfuQAlMokRmKmkDViLBgRDGVAHpawKFpGXSriJTSsC+tY61fwOsNEowkSWd15n0h4qJEqVrFtAzkZJRcJk7JTFKxRJp1lUZNwcgESacEHFktTS6vE4nGkC0bMW/iD0SYC8SZmosy44kST2aIJVLkzTxPALaZJZ8TqdgpLq7kadQymKpAMj6Pw8hJ5PQ0kXiEQEggHJPxCTLnPRLO8zJj8yq+mEVEtEhnLbKaTj6vYmgSdl5idUmnXpKxsgKGEsRRKSiUixp2wUDPGyiqhagu4k0tcU5YZCRYZzLeZFZaIaCskEgXsGyLvK5g6ikKhkjFFJ/GqpaIo1bMUq8YlMsWhWIBwygz1vyRM189xHn7PsN3HuC8/YCRr+/j+vJ3ZvRruOcjnBqcQFclzFyCohGnpMdYLKVwLBQVFooalZKJouXpn0jS2/iF03f/4+TNv3F9/y+j3z7GtfWIkeu/MZfZxM6YnHNNIUkxKoU0VVt6ur22rOFYqmSoV3SWFgrMBFO82+NndPNX2m/8w9Hmn/TefoTz7mOG7zxm6Dvoi29wZfMq937+ATUjoasxpJgbUw3RXEzjWF/W6D7Vz8S0m6iocOKMn3OrP9F5/S9OXv2DrssPOSxs0VG9x+DWIz72L5PJFlheWWNu9jPCvikE3zgZ0c1ySXwiTHHoyDGcrrMEhChdA/OE5Rqz0ipd7gYv9S+wo03k8KDJaKjOyIzE8JiPZ184wvud3UQjs8yNnybidVG1BBynerp5Zscr7Nn/Dic+6Wdixk8qpdI3GmH725P0TudoGxAZdBtIksn4pI/2bifH32wjeOwEQs9JLtghVsthhgY6cHR3fMhzO/axa28rO198C4/Hg5hI0DfiZtuBLj49G8A5IeCcjODxRpmanmbovU5mWg7h2XYAbffrVDwu5NAILS0tON5ofZU9u1/g4IGD7Nr7GkfbOhgYGEYIePDOnWdoeJy29j4+aO+ls6uP9rbjfNTSypmd+xnb9TLDu/cxcKSVoZ6jPL9zO/8DNFnU28AfaW4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "image12",
            "title": "image12",
            "src": "/static/eda5ea72d967252fd62391e448803d0f/31198/image12.png",
            "srcSet": ["/static/eda5ea72d967252fd62391e448803d0f/5a46d/image12.png 300w", "/static/eda5ea72d967252fd62391e448803d0f/0a47e/image12.png 600w", "/static/eda5ea72d967252fd62391e448803d0f/31198/image12.png 694w"],
            "sizes": "(max-width: 694px) 100vw, 694px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "573px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1b845a71ad4fc1a982718d4ed14687fd/3c024/image6.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAADdElEQVQ4yx3Q22tbBQCA8fNW9yAoiIoDZbMOUUFFmNfNTXRT54Zbp1vbhDXreknSS5qcNOck55KTnJPk5Jzcr23SJjZbt25ULQymgjimTvYi+OqTiP+BExkon8yH7/X38AmDjTYbgw6XLl+k2elh5kus9/psb19lY6NLteKw1m1RL9tc3mgw6NX+r9PKkzEi2KZIOR9ns1/i+ucdhE7dZrVV5ovtLXZ2vsQyM6RyGWzHoeg69LptWo0KuiKRTco0ChaOmaBqK6SkAK6xRMkS6ZQ11ms6gpNVaNQc6s0a5VoVyzRxr/axakUSskQ8oRCLyczNzaGqCTJWmpKbJW+pJOMh6q6GrYdoFRXaFRXBzshUKzkqVZdsLo1mJJESMstyjKgcJxSRmF2QmJ6PkdBM4mqSrJ2jWHJwbIN6xSJvSXTbGS72XYRcOkq1mKJVz1FwDNIpBdMyUFQFWZIQwzLnZyQm/SqiZBNXHaxMkWw2j+vkqJUsnIxMq2rQX7EQmgWNbsNitZFlbbVAp12gVLRxXIdEeQ2xsknAHjCTGTBrXWIuu0mscIW41cJ1Czi2RSYlUXY0ilkJoVVQWL2vt7OEl4JMTU9RK+VIZ7KMrf/MqSt/cHrzV0au/Yb31l9M3LyL9+Y9fCu30RQT0zJJ6TIlR8exlhHuz1yp6nTqaRanzhG+4EOMioQTST4U+zwf/Ix94xVeDm8z8cPfjN34kzPf/ct4+w6LIQMtmcTQJGqlFM2ijtAuJlipqPSaJr/8+BWDXpfXDx8nnTI4Gmqyx9fgiZMuL0Z2mLwD41/fxfP9P3jat5lbSKLpBkk1RtnRaZZ1hG45wVpd4/ixI2QMhRs7W7x54D10TeHQdI7hkymeOmXzgn/Aqcu/4/32Hp6fYLR5i/lgHE1TkaIhTD1CwYog9GoK/YbOM3ufZnHGxzfXr/HWwXeIhucJiVFmF5f5yCOy+8A8jx9c5v3ACuf1i/jlGme9Cxz+YIzYUpC8IVI0RYR+XWVi7ASPPvIY+1/Zj2fsLD7Pp8iRAJGFAP7ZWQL+Rfa94WVkKkW5tk5wTiUW1Thy4hy7n32bQHAS/5QHceYMgs/7CcN79zD0wIMM7XqIoV0Pc+zou8z4Rnly+CX2PPca0zN+vBMB/Asx8m6Z0fELBIJBxk6c5vyrh0h+PEIzGWZr3eQ/GeGEe32v9WcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "image6",
            "title": "image6",
            "src": "/static/1b845a71ad4fc1a982718d4ed14687fd/3c024/image6.png",
            "srcSet": ["/static/1b845a71ad4fc1a982718d4ed14687fd/5a46d/image6.png 300w", "/static/1b845a71ad4fc1a982718d4ed14687fd/3c024/image6.png 573w"],
            "sizes": "(max-width: 573px) 100vw, 573px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "716px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3a8dc6c97efbb78666593b8186651643/6bbf7/image7.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEF0lEQVQ4yx3R209TBwCA8ZPtbTFmWRYdu7gu9e6WCW5OnYg6ZW6YTpnUCSjVChQELCAUobSUa4G29Gbp7ZwWenqjYAflJsjEG7oly15MliXLXsyelu1f+Jb4/iW/h0+YCIwR9duIiy6WMyLLqXFCo10YmrQ4Bk2IPgfT8TBTskQ8GiQuhbm/tMJ///zLVDrFo/XHPHn4AMnXTzbhRJAnJGTpDosZiaV0iJmIk7kJJ5ExM9236hnquU3QM4roGyPgseN3O5hJJnn5159Mp2XWVtd4uLZIJjqCc6QDQb67TkhK4nGN4XcOEg+MspTys5IJkAxYGes3YLW043NYGDC1YDU3kxBt/LKeJZcRmZE9zCZdpCLDtDWUIyw8+oO5n16QyT0lGBQZ7etkKupl416ajcUYy2k/rsF2/HYjfkcnrQ2VWDrr8Nq7GBnooLujnv7uRvQ3LtN9vQRBnsqRzN4nu/iMRGae4aE+fONBxIiMGPC9AtoaNNRpLmCztFCjKUX1bSG1WjVNDVoadZUYmjU0VJdhuqFGWF17wtLqY9LZFTzjEdpa9YyHYsR/fEI4scKQ1YahuY7Wplr09VramrQ01VXSebsZp9vNwICJHlMz7c3X0etrEBYX5sktrjG/8pzJ1ALGTgMWczfp6XmW7z8jnpohHBaZzc4yO50hExMZ6mnH67Dy/NkGU6lJ3KMWes0GjN0GhN8epFidjbKUlUnGJ4kE7K+CCTHIZCRKKplCFEVCoTABf4yQN4J0x44sOpDDTiT/KKLfTiwawmQxI5SXqwl7zazPuZHGB1idC3EvJyNH3NiGjPjcDryOfnyOYcyxDbTZv+mL3CPothKfcLOykCSVEIlNinSaLQjKnUp8I43Mx0ykpH6k8UHctj4CnsFXM4bMbRjbdNys1WJJPKUi+ZJaU4xcXCIp2UhHXUxnZEKixC1TH8KIWcNwz3XqqkqwdWk5XXSIy+eKCNg6KD5+lIJPP+bs18epv3YJY0sjF76v4oyyAJeuCmPrNT7ZreRq1Q9MSne42WtH+P2hlfDwFdTF+ykrPMD5onxqzn/B/l3vsv2DPK6cPYHm3El6G9Soju6jtkqNfaALn0mL+sReblVfJBbxE/IOUV2nQ7jrucyvqRoMmmPs/FjJwQMfsUvxDps3b2KfYgutZYU49JcoPbab0qNKovZ21pcSzElGzNVFtGhL0VWoqPjuJJpyFYJovcKLnAH9pUOc+Ox9CvJ3sHPHhwiCgPD6a+zN20T7VRUlR/ZQeWoPFad2U3xAwWiLikhvGUf25nH2sBKXQUVt+SmE+PBFnidv4DWUcCZ/K18d3EVl8T6KC/LY+tYbKLZt4728LexQvMmZL5Wojmzn9OdK9ijeprumkJLDCnSl+fwc1+Fs/4b/AXQa6r9Bor3KAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "image7",
            "title": "image7",
            "src": "/static/3a8dc6c97efbb78666593b8186651643/6bbf7/image7.png",
            "srcSet": ["/static/3a8dc6c97efbb78666593b8186651643/5a46d/image7.png 300w", "/static/3a8dc6c97efbb78666593b8186651643/0a47e/image7.png 600w", "/static/3a8dc6c97efbb78666593b8186651643/6bbf7/image7.png 716w"],
            "sizes": "(max-width: 716px) 100vw, 716px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`次々に参加者が増えていき、最終的には 50 名近くの方にご覧いただきました 🙌
オンライン配信を含め、ご視聴いただいた皆様、ありがとうございました！`}</p>
    <p>{`登壇した髙橋より一言`}</p>
    <hr></hr>
    <p>{`セッションや登壇資料をご覧頂いた皆様、ありがとうございました！当日のブースや懇親会、また X やブログを通じて、皆様から多くのご感想を頂き感謝の気持ちでいっぱいです。発表で詳しく取り上げられなかった内容に関する Q&A を以下に公開します。`}</p>
    <h3>{`コンポーネントの分割粒度をチーム全体でどのように統一していますか？`}</h3>
    <p>{`発表内で紹介した{関心事}{状況}{ベースコンポーネント名}の命名規則を守ることで Button や Dialog の単位で自然とコンポーネントが分割されるようなメンタルモデルを築いています。加えて `}<a parentName="p" {...{
        "href": "https://plopjs.com/"
      }}>{`Plop`}</a>{` を使ってテンプレートコードを生成することで実装がブレないようにしています。`}</p>
    <h3>{`状態管理ライブラリはどのように使い分けていますか？`}</h3>
    <p>{`非同期状態管理は `}<a parentName="p" {...{
        "href": "https://tanstack.com/query/latest"
      }}>{`Tanstack Query`}</a>{`、フォーム状態管理は `}<a parentName="p" {...{
        "href": "https://www.react-hook-form.com/"
      }}>{`React Hook Form`}</a>{`、その他の UI に関する状態は React.useState のように使い分けています。複合コンポーネント間で親子関係がある場合は React.createContext を使って依存関係を表現することがあります。このとき、子コンポーネントの `}<a parentName="p" {...{
        "href": "https://storybook.js.org/"
      }}>{`Storybook`}</a>{` の decorator に Context Provider を設定することで、複合コンポーネント間の連携を含めた Story とそのテストを実装しています。`}<a parentName="p" {...{
        "href": "https://redux.js.org/"
      }}>{`Redux`}</a>{` はサーバキャッシュとして運用していましたが、現在は Tanstack Query への移行を進めているため積極的な利用は避けています。`}</p>
    <hr></hr>
    <p>{`👇 登壇資料はこちら`}</p>
    <iframe className="speakerdeck-iframe" frameBorder="0" src="https://speakerdeck.com/player/1776024da9354b3b96f2e593d1b5bd16" title="徹底解剖！ 医療業務システムのReactコンポーネント設計 / Deep Dive into React Component Design for Medical Systems" allowFullScreen="true" style={{
      "border": "0px",
      "background": "padding-box padding-box rgba(0, 0, 0, 0.1)",
      "margin": "0px",
      "padding": "0px",
      "borderRadius": "6px",
      "boxShadow": "rgba(0, 0, 0, 0.2) 0px 5px 40px",
      "width": "100%",
      "height": "auto",
      "aspectRatio": "560 / 315"
    }} data-ratio="1.7777777777777777"></iframe>
    <h2>{`德永のセッションの様子`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "658px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b8a58d44ddd8632a110ea8600e09f918/889a4/image3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEHklEQVQ4yx3O2U/TBwDA8d/jHjYflmVZshefTMw02WGyZRozNMY42ESZOEARcdZxRKFBkJrCEKiltbSlQKUnLS2sUCk9gEKhFEuLIiAy45hLNCYeExDQgQcq38X9A598BL/bSaj7Esa6GtTyMqoqpFRJy1DWqNDpTVg6ejG0uGm0udA7PZhdPvQWJyqVmqK8HPKz0ikUZSI6dIAjqXsR3OY6LJoqWpoNOC+5abI40OhaaXSEsHeFcQWjOP2DOHwD2LtC1FtdyOstyGpUlJX9RvnZUo6kHyRPlElS4m6EiuJ8KiVitLomjK4+TO39mLpiWIJT2Psn0DgCGLxRzN4IamMblTo7p6u1lCsakMrUHEzL5IykFEmJmPTUfQjSk9mcPXWc4oICCsVnyS0oI6ewgqMF58jIlbLzx2OkZJciKlaSJ64gOS2PHUmH+T45k5/SfiEjPYNQax2DPW4GBoMI/S11dF6U4/b20D7xCFvsNsboX+hDf9AWmcQTn6ZtaBxTIIra2YfS4qVa76Ja24xcpkQlO0fcpeVWj4GYuw6h26LGd/E8ZvcIxmnoeQih2TWGn8ONV3DrxRp/rsLNVZhcgdgixJZg6il4LnnodTXT21yLpaaEpqoChIBZhVdfhcYW57gL1J2P6O4aQupfwH9vjfiDOaIPlhidXSY+t8Lg/WVG5t+g6xhCLlMR8baiLS/iUEoSCdu3IvRYL+BtvEBrr4aLf0txj5XhcfZT5JohcP8F15deMTr/krHF11xdes3owiqxxbdITldz8mgusb5OysU5ZKfvY8+uhHegHG+Dls5QGr/f+wT73Y1IDTOcccwwNHeHiflV4v/vXjAy+5zY0huu3HlAvN2OTV7FeL+bc0U5FOdmkp6ShNBnVxDQ1+AL1+K/bcAW8VBy4QkSxV36Zv5l6skyo49XiP6zQnTuJdHFNSavxBgw6aiTFOPUyCgTi6gsyeHIz3sRwg4lQZOMoKed7qE7NFieoaufQttwjfCtBa7PLnH14TKxR8+5/HiVsflVYm0W8lNTSfwuAdH+RKpOZqAqySLrwB6EYaeSwWYFYVc9I5f9+FyddJittDb7uHp3nptP3zI+95rJBZh+BkprJ6UHf8CVn0SLKAHniW3Yj3+D9ejnZO/chBB1KhluURJp1TLsMRO0a+kyqPA5jQyPXSMyPk1k7AaB6ATWwDBfb9+DdP+3BMW76Ph1K+0ntmDK2IRu/3oqj+1FmOioZeQdalcQtsjpMysIGJX4jWo8TSp6bQ30OZpwmRqRlJTy3gcfkbNjI8FTW7Ee3ow96zMMaRuo3v0xzQUpCG7tGUYcci7bzxO2ygiZqgkaZXQb5AzYtShKC/nqyy9IS07EVlvB++vWIdq2Hq9oM7bDG2g6sB5N8qdU7PyQwn1b+A9pLE1mljgWaAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "image3",
            "title": "image3",
            "src": "/static/b8a58d44ddd8632a110ea8600e09f918/889a4/image3.png",
            "srcSet": ["/static/b8a58d44ddd8632a110ea8600e09f918/5a46d/image3.png 300w", "/static/b8a58d44ddd8632a110ea8600e09f918/0a47e/image3.png 600w", "/static/b8a58d44ddd8632a110ea8600e09f918/889a4/image3.png 658w"],
            "sizes": "(max-width: 658px) 100vw, 658px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "653px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/744add27866f6bb722fe18ab146a2f7e/e7dce/image10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEJElEQVQ4yyXOS0yTBwDA8e+2KLVAYUOdMsUnzDBwAzcRBYaIIsh0gJNKBTsUiAXaj68vRRD6ok1rKUUe0heP8gaNThPmxGmWxW1myzI9uR2MycZl8bAsO/0X4+F3/ecvpKenoTxTg7/fw3WfDZ/ViNfRjqfPzfXRAP3hAJ6hAUzGVppqT1BfXcbxwj18XpxDScEeDu3/kAPZu/g0J5PsD7YjVFaWUV1ZikZdxWWtGp/NjL3bRIfLji0YwTM+hd3fx8UOM/WqE5w9VUr18YMczsuiICeT/JxM8vZmkJ62hbRtyQj3/3hBYHERbYOSM8cLUVWVoFYrkew2fLeWCD36CVckirGzHfVZJUcOF7Bv725Sd2xmW8pGUpKT2LIxkX27t5KxYz3CreUlvv/7X6YDPkJWPS5jE2LDabTtZlpsDlSiRJ3ejLq1lYrKYyiVlRzY/zFZGTvJSt9KeX4mUUcDjyc6eTBiQpi/OcnDlytE/D0ErHpGrHoGei6x8PQZk78+wxIZQyW2kZu3D31tCQv9Fwl6O3GYGqkpz2PeZ+C7qJNvQjYWek0IN25P8+jlS8b8DoKWNoa7RXzdeuae/sLSq39YfvUfk0+eYvP30nmhGt/FBiytNfRdaaHXWM94j0SPporhy+eY80gIi/MRHv7+nIk+KyGryIhFxG+RmP35MXdWVrjx/AXe0XEGxwIMOiR6u7S0qsq5olEy421nxmtm1ClyL+Lk9lA3wt0HSyz/9oyo30LEoSNkExmy6Zl98pilv1a48+JPhm99hWugj5DbSNSloaU8i7OFqbTXlXGuphhj4wmWIi7chi8R7v34A3fvLzHZ18loj46wQ0fAIRG9OcPM8tdMLy8z9e0jbC4bmpoSblgraCtYS332GhqL0qhXFaFRFWNpqaWj6RTCrNfEbK+Z6asGJt1tjLtERntEwnaRgFVksFtHX0cr3o5WXNoqxps/oq3gHdRZcmpzN9FcU4LTWEenRsmBjBSEBa+e1+a9BmavSkx72ph0i0TdEhNuPSGHyFCXFoexEVtzBRMN7yPlx3M+O4ZD22XsTNnAF0dzqa0oInXTOoTFqxKL3jdeh+e8BqY9esadOoKWC/RfqqPXUI1TrMLScIhrZ3aiy0/g3CfxFKfK2ZAUT1K8jOS1Ct5NjEOYc0tMuUTG7c2EuxoYaa9l2HyKIWMFQ+YKAldOE7SrifTUE7VVc605l+ajmynapWBbUgzrEtawViEnMTaGBPlqhEHpJAO6zxjSHWVYe5iAVMKIoZRgx0nGXE3MDF5iIewk2m/C2lRE9f73SE+W83acDEVcLInxchJiZSjkMcTJViEMthwkIB0hZCwlbD5G0FhGuKOSMdd5Zke6uDnlx2eXqCzMIG39m4vYuFgUingU8bHEyWXIZTGsiVmFbPVb/A90cL5DAKZABQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "image10",
            "title": "image10",
            "src": "/static/744add27866f6bb722fe18ab146a2f7e/e7dce/image10.png",
            "srcSet": ["/static/744add27866f6bb722fe18ab146a2f7e/5a46d/image10.png 300w", "/static/744add27866f6bb722fe18ab146a2f7e/0a47e/image10.png 600w", "/static/744add27866f6bb722fe18ab146a2f7e/e7dce/image10.png 653w"],
            "sizes": "(max-width: 653px) 100vw, 653px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "664px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/30000adc41249446a6bd9b0eb0f40157/31493/image9.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAELElEQVQ4yx3O7VPTBQDA8d8fUJdeIoIxBF0oCoQGQoAmD+NhCIxtsA0YG5uMB5ljwJgITBmMwRjI05wDReTBB+hUMNN8OC/IOK3UziyP8qwX3vmirv/g252vPm8/QqshB012LJrsOJSZMcjTd5OfGoUkSUzuF1EoMmIxypJp0mXhOFqM/5SOW7Nu7lwaYKBNT2e9Aq9NhbO+kMayDARfTz2DxzUsjVQx36umz5xNU1kKVQWJlEr2opUmYlIexGaQMnKyhom+Y9xfOM3qzbMMdVlwNFbQUa+kTpVFac5+hIHOWnw9DYx3lNFpyqJZe5Dq4iSUkr3kHYxDmpFAUW4aWkU2TpuBCa+dpflxnq0uMj/Zzxl3C90tehq0+ailqQhXpkdYmBnG3WagWpHCEVnie/XyLynOTaFEnoNGXYxRr6a9qZoZn5MHyzP8+ew23y1PsTDZz1iPla7GcsxaKYLf28by4gTXLo1SW55DlfIQXc0VdDWV02BUYLK2UNfhotU9RI+nl7kJD09Wlnm9/pTnP9zmzsIEgYEOHJZKasvyEIZPGulvN+A6bsRer6DdUsa9pUkWpz1c9J1i7tHPLK6/48bf/zK/8j3XZ0d59fIJ7/75jzfrT1m7c4mli0OcPtlAV2MZwr3LbmbGOrCZivG5rZzxNNF/ysyUv5cpv4e+qVmc8zfpnbvB8IVprs35eP7TCn+9fctvv6zx4MY002Pd+N1WrozZEJ7cGqPbZqS9XkGnVcu428JoTy3nRto53d+B3qjjSMMxWp1u3EODnPd7uX/7K168+JFHq3dZnPExM+rgqs/O/HATwh9rAU40yTBX5nFY8jkmXRb3rvbxcnUOn7uR6koZgSkfD1+/4cGr37l+2c+3D7/h8fo69x+vcWXuLFODrUx4zCz6WxF+XfHRbEzHUJhKSU485cqU98M2iw5bQxmGChl6ox6704VnZJiOE814vS7OX5hgMjBKt6MVW50GU3keAyf0CI5GNYnRkXRZVJzrqyMhTkxGSjyxu7ZjNirobqshUixGVpCDSa9CXSrDqFPR57CyEHBy1KCmUi5BlZdGakIMQox4K2JRMIVZyciyk4iOCCZpTySRohDCw0IoliRhNxZRrcpFWZRFkTSd4vxMSgqzaTaVMG4vx1FdhDwzkYIDnyGEB31ARPBHhIUGIRYFEbllA3u2h7J500ZCg4Pez+/Ough47eRnJpOUEIelRoOlRkWjtoCu8gys8jRKD8SizYxHKEoWERW+iegdIcTu3Ma+2E8RhW5ia/DHxERFoJMdwmmt4OtpF6WFGeyN3Umgv4UzbivjNgOS+F2Iw7awTxzG/uhtCC5zDs6jeWgL9qNR5qJTSwna+CEhmzewQxRCRnIcenkWVUoJ8tw0xBHhSNOTaK3VEOhpplZ9mJ3bPmHPdhG7I7fyP9csr2jLdIBXAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "image9",
            "title": "image9",
            "src": "/static/30000adc41249446a6bd9b0eb0f40157/31493/image9.png",
            "srcSet": ["/static/30000adc41249446a6bd9b0eb0f40157/5a46d/image9.png 300w", "/static/30000adc41249446a6bd9b0eb0f40157/0a47e/image9.png 600w", "/static/30000adc41249446a6bd9b0eb0f40157/31493/image9.png 664w"],
            "sizes": "(max-width: 664px) 100vw, 664px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`登壇した徳永より一言`}</p>
    <hr></hr>
    <p><a parentName="p" {...{
        "href": "https://remix.run/"
      }}>{`Remix`}</a>{` のファイルベースルーティングについて LT させていただきました。当日に React Router v7 が正式リリースされるなど想定外のことが起こりつつも、皆さんにホットな話題として取り上げていただき有り難かったです。`}</p>
    <hr></hr>
    <p>{`👇 登壇資料はこちら`}</p>
    <iframe className="speakerdeck-iframe" frameBorder="0" src="https://speakerdeck.com/player/d0661a266a58412c847e36326909c64b" title="Remix SPAモードのファイルベースルーティングで進めるフロントエンド構築" allowFullScreen="true" style={{
      "border": "0px",
      "background": "padding-box padding-box rgba(0, 0, 0, 0.1)",
      "margin": "0px",
      "padding": "0px",
      "borderRadius": "6px",
      "boxShadow": "rgba(0, 0, 0, 0.2) 0px 5px 40px",
      "width": "100%",
      "height": "auto",
      "aspectRatio": "560 / 315"
    }} data-ratio="1.7777777777777777"></iframe>
    <h1>{`終わりに`}</h1>
    <p>{`メドレーはこれからも医療ヘルスケア領域において、医療体験にインパクトを与えるプロダクトやサービスの開発に取り組んでいきます。引き続き技術的なチャレンジも行っていきますのでご注目ください！`}</p>
    <p>{`最後に、改めて JSConf JP 2024 の運営の皆様、登壇されたスピーカーの皆様、参加者の皆様、ありがとうございました。来年の開催も楽しみにしています！`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/025d2c2c32221a7b8c3d7c6241325d84/c58a3/image14.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "133.33333333333331%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMBBAUG/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgED/9oADAMBAAIQAxAAAAG3OMqXphJk8h2zV0LRQF//xAAbEAADAQADAQAAAAAAAAAAAAABAgMAEBESIv/aAAgBAQABBQKjiQW024ozXpObUyL5RpUiYH67x3kA7//EABYRAQEBAAAAAAAAAAAAAAAAAAEQEv/aAAgBAwEBPwHUCf/EABgRAAIDAAAAAAAAAAAAAAAAAAEQAhMh/9oACAECAQE/Aa8Rmv/EAB0QAAEEAgMAAAAAAAAAAAAAAAEAAhARITEiUZH/2gAIAQEABj8Ctyw72MLigLVjXYQDdTYG4//EAB0QAAEDBQEAAAAAAAAAAAAAAAEAETEQIUFRYXH/2gAIAQEAAT8hHHLnCjwHVlLNeDQR4gI6jv4MEzp0MYS7WdgAZNT/2gAMAwEAAgADAAAAEJPjPP/EABkRAAMAAwAAAAAAAAAAAAAAAAABERAhUf/aAAgBAwEBPxBvdY4kP//EABcRAQEBAQAAAAAAAAAAAAAAAAEAMRH/2gAIAQIBAT8QBrZJMbvb/8QAHxABAAMAAgEFAAAAAAAAAAAAAQARITFBYVFxgaHB/9oACAEBAAE/EDsRUFb8yqHHkfczpyIhlbDeDYNhrbdPj3hDYYlDvcTOqp5A/ILWarNetsbO7OM7q+Crlz//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ミイダス社とメルカリ社の素敵なノベルティをいただきました✨",
            "title": "ミイダス社とメルカリ社の素敵なノベルティをいただきました✨",
            "src": "/static/025d2c2c32221a7b8c3d7c6241325d84/e5166/image14.jpg",
            "srcSet": ["/static/025d2c2c32221a7b8c3d7c6241325d84/f93b5/image14.jpg 300w", "/static/025d2c2c32221a7b8c3d7c6241325d84/b4294/image14.jpg 600w", "/static/025d2c2c32221a7b8c3d7c6241325d84/e5166/image14.jpg 1200w", "/static/025d2c2c32221a7b8c3d7c6241325d84/c58a3/image14.jpg 1500w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`ミイダス社とメルカリ社の素敵なノベルティをいただきました ✨`}</em></p>
    <h1>{`メドレーでは一緒に働く仲間を募集しています。`}</h1>
    <p>{`ぜひこの記事やブースや登壇などで興味を持っていただいた方はご連絡をお待ちしております！カジュアル面談も大歓迎ですので、お気軽にお問い合わせください！`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="募集職種 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Fjobs%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"></cite>
    <p>{`当日会場でお時間いただいた皆様、ありがとうございました！`}</p>
    <p>{`メドレーは今後も技術イベントへ出展/登壇する予定です。今後も皆さんにお会いすることを楽しみにしています！`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      